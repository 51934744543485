import React from 'react'

function SizeChart() {
  return (
    <div className='w-[100%] px-[5%] h-[auto] lg:py-[2%] pt-[2%] font-[FrutigerLTCom-Roman]'>
    <div className=' px-[2%]'>
    <h1 className='text-[40px] font-[FahKwang]  lg:text-[40px] md:text-[25px] md:py-[5%]'>SIZE CONVERSION TABLE</h1>
    <div className='border-[1px] text-[16px] h-[auto] lg:overflow-x-auto'>
    <div className='min-w-[800px]'>
    <div className=' h-[50px] text-center flex justify-between items-center'>
        <p className='border-[1px] border-[#e3e2e2] flex justify-center items-center h-[100%] text-center w-[20%] bg-[#d0d0d0]'>USA/USA Letters</p>
        <p className='border-[1px] border-[#e3e2e2]  flex justify-center items-center h-[100%] text-center w-[10%] bg-[#d0d0d0] '>XS</p>
        <p className='border-[1px] border-[#e3e2e2]  flex justify-center items-center h-[100%] text-center w-[10%] bg-[#d0d0d0]'>S</p>
        <p className='border-[1px] border-[#e3e2e2]  flex justify-center items-center h-[100%] text-center w-[10%] bg-[#d0d0d0]'>M</p>
        <p className='border-[1px] border-[#e3e2e2]  flex justify-center items-center h-[100%] text-center w-[10%] bg-[#d0d0d0]'>L</p>
        <p className='border-[1px] border-[#e3e2e2]  flex justify-center items-center h-[100%] text-center w-[10%] bg-[#d0d0d0]'>XL</p>
        <p className='border-[1px] border-[#e3e2e2]  flex justify-center items-center h-[100%] text-center w-[10%] bg-[#d0d0d0]'>2XL</p>
        <p className='border-[1px] border-[#e3e2e2]  flex justify-center items-center h-[100%] text-center w-[10%] bg-[#d0d0d0]'>3XL</p>
        <p className='border-[1px] border-[#e3e2e2]  flex justify-center items-center h-[100%] text-center w-[10%] bg-[#d0d0d0]'>4XL</p>
        <p className='border-[1px] border-[#e3e2e2]  flex justify-center items-center h-[100%] text-center w-[10%] bg-[#d0d0d0]'>5XL</p>
    </div>
    <div className=' h-[50px] text-center flex justify-between items-center'>
        <p className='border-[1px] border-[#e3e2e2] flex justify-center items-center h-[100%] text-center w-[20%] '>EU/Europeans</p>
        <p className='border-[1px] border-[#e3e2e2]  flex justify-center items-center h-[100%] text-center w-[10%]'>36</p>
        <p className='border-[1px] border-[#e3e2e2]  flex justify-center items-center h-[100%] text-center w-[10%] '>38</p>
        <p className='border-[1px] border-[#e3e2e2]  flex justify-center items-center h-[100%] text-center w-[10%]'>40</p>
        <p className='border-[1px] border-[#e3e2e2]  flex justify-center items-center h-[100%] text-center w-[10%] '>42</p>
        <p className='border-[1px] border-[#e3e2e2]  flex justify-center items-center h-[100%] text-center w-[10%]'>44</p>
        <p className='border-[1px] border-[#e3e2e2]  flex justify-center items-center h-[100%] text-center w-[10%] '>46</p>
        <p className='border-[1px] border-[#e3e2e2]  flex justify-center items-center h-[100%] text-center w-[10%]'>48</p>
        <p className='border-[1px] border-[#e3e2e2]  flex justify-center items-center h-[100%] text-center w-[10%] '>50</p>
        <p className='border-[1px] border-[#e3e2e2]  flex justify-center items-center h-[100%] text-center w-[10%]'>52</p>
    </div>
    <div className=' h-[50px] text-center flex justify-between items-center'>
        <p className='border-[1px] border-[#e3e2e2] flex justify-center items-center h-[100%] text-center w-[20%] bg-[#d0d0d0]'  >LB/Lebanese Designers
</p>
        <p className='border-[1px] border-[#e3e2e2]  flex justify-center items-center h-[100%] text-center w-[10%] bg-[#d0d0d0]'>0</p>
        <p className='border-[1px] border-[#e3e2e2]  flex justify-center items-center h-[100%] text-center w-[10%] bg-[#d0d0d0] '>1</p>
        <p className='border-[1px] border-[#e3e2e2]  flex justify-center items-center h-[100%] text-center w-[10%] bg-[#d0d0d0]'>2</p>
        <p className='border-[1px] border-[#e3e2e2]  flex justify-center items-center h-[100%] text-center w-[10%] bg-[#d0d0d0] '>3</p>
        <p className='border-[1px] border-[#e3e2e2]  flex justify-center items-center h-[100%] text-center w-[10%] bg-[#d0d0d0]'>4</p>
        <p className='border-[1px] border-[#e3e2e2]  flex justify-center items-center h-[100%] text-center w-[10%] bg-[#d0d0d0] '>5</p>
        <p className='border-[1px] border-[#e3e2e2]  flex justify-center items-center h-[100%] text-center w-[10%] bg-[#d0d0d0]'>6</p>
        <p className='border-[1px] border-[#e3e2e2]  flex justify-center items-center h-[100%] text-center w-[10%] bg-[#d0d0d0] '>7</p>
        <p className='border-[1px] border-[#e3e2e2]  flex justify-center items-center h-[100%] text-center w-[10%] bg-[#d0d0d0]'>8</p>
    </div>
    <div className=' h-[50px] text-center flex justify-between items-center'>
        <p className='border-[1px] border-[#e3e2e2] flex justify-center items-center h-[100%] text-center w-[20%]'>IT/Italian</p>
        <p className='border-[1px] border-[#e3e2e2]  flex justify-center items-center h-[100%] text-center w-[10%]'>40</p>
        <p className='border-[1px] border-[#e3e2e2]  flex justify-center items-center h-[100%] text-center w-[10%]'>42</p>
        <p className='border-[1px] border-[#e3e2e2]  flex justify-center items-center h-[100%] text-center w-[10%]'>44</p>
        <p className='border-[1px] border-[#e3e2e2]  flex justify-center items-center h-[100%] text-center w-[10%]'>46</p>
        <p className='border-[1px] border-[#e3e2e2]  flex justify-center items-center h-[100%] text-center w-[10%]'>48</p>
        <p className='border-[1px] border-[#e3e2e2]  flex justify-center items-center h-[100%] text-center w-[10%]'>50</p>
        <p className='border-[1px] border-[#e3e2e2]  flex justify-center items-center h-[100%] text-center w-[10%]'>52</p>
        <p className='border-[1px] border-[#e3e2e2]  flex justify-center items-center h-[100%] text-center w-[10%]'>54</p>
        <p className='border-[1px] border-[#e3e2e2]  flex justify-center items-center h-[100%] text-center w-[10%]'>56</p>
    </div>
    <div className=' h-[50px] text-center flex justify-between items-center'>
        <p className='border-[1px] border-[#e3e2e2] flex justify-center items-center h-[100%] text-center w-[20%] bg-[#d0d0d0]'>UK/United Kingdom</p>
        <p className='border-[1px] border-[#e3e2e2]  flex justify-center items-center h-[100%] text-center w-[10%] bg-[#d0d0d0]'>8</p>
        <p className='border-[1px] border-[#e3e2e2]  flex justify-center items-center h-[100%] text-center w-[10%] bg-[#d0d0d0]'>10</p>
        <p className='border-[1px] border-[#e3e2e2]  flex justify-center items-center h-[100%] text-center w-[10%] bg-[#d0d0d0]'>12</p>
        <p className='border-[1px] border-[#e3e2e2]  flex justify-center items-center h-[100%] text-center w-[10%] bg-[#d0d0d0]'>14</p>
        <p className='border-[1px] border-[#e3e2e2]  flex justify-center items-center h-[100%] text-center w-[10%] bg-[#d0d0d0]'>16</p>
        <p className='border-[1px] border-[#e3e2e2]  flex justify-center items-center h-[100%] text-center w-[10%] bg-[#d0d0d0]'>18</p>
        <p className='border-[1px] border-[#e3e2e2]  flex justify-center items-center h-[100%] text-center w-[10%] bg-[#d0d0d0]'>20</p>
        <p className='border-[1px] border-[#e3e2e2]  flex justify-center items-center h-[100%] text-center w-[10%] bg-[#d0d0d0]'>22</p>
        <p className='border-[1px] border-[#e3e2e2]  flex justify-center items-center h-[100%] text-center w-[10%] bg-[#d0d0d0]'>24</p>
    </div>
    <div className=' h-[50px] text-center flex justify-between items-center'>
        <p className='border-[1px] border-[#e3e2e2] flex justify-center items-center h-[100%] text-center w-[20%]'>US /USA</p>
        <p className='border-[1px] border-[#e3e2e2]  flex justify-center items-center h-[100%] text-center w-[10%]'>4</p>
        <p className='border-[1px] border-[#e3e2e2]  flex justify-center items-center h-[100%] text-center w-[10%]'>6</p>
        <p className='border-[1px] border-[#e3e2e2]  flex justify-center items-center h-[100%] text-center w-[10%]'>8</p>
        <p className='border-[1px] border-[#e3e2e2]  flex justify-center items-center h-[100%] text-center w-[10%]'>10</p>
        <p className='border-[1px] border-[#e3e2e2]  flex justify-center items-center h-[100%] text-center w-[10%]'>12</p>
        <p className='border-[1px] border-[#e3e2e2]  flex justify-center items-center h-[100%] text-center w-[10%]'>14</p>
        <p className='border-[1px] border-[#e3e2e2]  flex justify-center items-center h-[100%] text-center w-[10%]'>16</p>
        <p className='border-[1px] border-[#e3e2e2]  flex justify-center items-center h-[100%] text-center w-[10%]'>18</p>
        <p className='border-[1px] border-[#e3e2e2]  flex justify-center items-center h-[100%] text-center w-[10%]'>20</p>
    </div>
    <div className=' h-[50px] text-center flex justify-between items-center'>
        <p className='border-[1px] border-[#e3e2e2] flex justify-center items-center h-[100%] text-center w-[20%] bg-[#d0d0d0]'>RU/Russian</p>
        <p className='border-[1px] border-[#e3e2e2]  flex justify-center items-center h-[100%] text-center w-[10%] bg-[#d0d0d0]'>40</p>
        <p className='border-[1px] border-[#e3e2e2]  flex justify-center items-center h-[100%] text-center w-[10%] bg-[#d0d0d0]'>42/44</p>
        <p className='border-[1px] border-[#e3e2e2]  flex justify-center items-center h-[100%] text-center w-[10%] bg-[#d0d0d0]'>46</p>
        <p className='border-[1px] border-[#e3e2e2]  flex justify-center items-center h-[100%] text-center w-[10%] bg-[#d0d0d0]'>48</p>
        <p className='border-[1px] border-[#e3e2e2]  flex justify-center items-center h-[100%] text-center w-[10%] bg-[#d0d0d0]'>50</p>
        <p className='border-[1px] border-[#e3e2e2]  flex justify-center items-center h-[100%] text-center w-[10%] bg-[#d0d0d0]'>54</p>
        <p className='border-[1px] border-[#e3e2e2]  flex justify-center items-center h-[100%] text-center w-[10%] bg-[#d0d0d0]'>58</p>
        <p className='border-[1px] border-[#e3e2e2]  flex justify-center items-center h-[100%] text-center w-[10%] bg-[#d0d0d0]'>60/62</p>
        <p className='border-[1px] border-[#e3e2e2]  flex justify-center items-center h-[100%] text-center w-[10%] bg-[#d0d0d0]'>64</p>
    </div>
    <div className=' h-[50px] text-center flex justify-between items-center'>
        <p className='border-[1px] border-[#e3e2e2] flex justify-center items-center h-[100%] text-center w-[20%]'>GER/German</p>
        <p className='border-[1px] border-[#e3e2e2]  flex justify-center items-center h-[100%] text-center w-[10%]'>32</p>
        <p className='border-[1px] border-[#e3e2e2]  flex justify-center items-center h-[100%] text-center w-[10%]'>34</p>
        <p className='border-[1px] border-[#e3e2e2]  flex justify-center items-center h-[100%] text-center w-[10%]'>36</p>
        <p className='border-[1px] border-[#e3e2e2]  flex justify-center items-center h-[100%] text-center w-[10%]'>38</p>
        <p className='border-[1px] border-[#e3e2e2]  flex justify-center items-center h-[100%] text-center w-[10%]'>40</p>
        <p className='border-[1px] border-[#e3e2e2]  flex justify-center items-center h-[100%] text-center w-[10%]'>42</p>
        <p className='border-[1px] border-[#e3e2e2]  flex justify-center items-center h-[100%] text-center w-[10%]'>44</p>
        <p className='border-[1px] border-[#e3e2e2]  flex justify-center items-center h-[100%] text-center w-[10%]'>46</p>
        <p className='border-[1px] border-[#e3e2e2]  flex justify-center items-center h-[100%] text-center w-[10%]'>48</p>
    </div>
    <div className=' h-[50px] text-center flex justify-between items-center'>
        <p className='border-[1px] border-[#e3e2e2] flex justify-center items-center h-[100%] text-center w-[20%] bg-[#d0d0d0]'>AU/Australian</p>
        <p className='border-[1px] border-[#e3e2e2]  flex justify-center items-center h-[100%] text-center w-[10%] bg-[#d0d0d0]'>8</p>
        <p className='border-[1px] border-[#e3e2e2]  flex justify-center items-center h-[100%] text-center w-[10%] bg-[#d0d0d0]'>10</p>
        <p className='border-[1px] border-[#e3e2e2]  flex justify-center items-center h-[100%] text-center w-[10%] bg-[#d0d0d0]'>12</p>
        <p className='border-[1px] border-[#e3e2e2]  flex justify-center items-center h-[100%] text-center w-[10%] bg-[#d0d0d0]'>14</p>
        <p className='border-[1px] border-[#e3e2e2]  flex justify-center items-center h-[100%] text-center w-[10%] bg-[#d0d0d0]'>16</p>
        <p className='border-[1px] border-[#e3e2e2]  flex justify-center items-center h-[100%] text-center w-[10%] bg-[#d0d0d0]'>18</p>
        <p className='border-[1px] border-[#e3e2e2]  flex justify-center items-center h-[100%] text-center w-[10%] bg-[#d0d0d0]'>20</p>
        <p className='border-[1px] border-[#e3e2e2]  flex justify-center items-center h-[100%] text-center w-[10%] bg-[#d0d0d0]'>22</p>
        <p className='border-[1px] border-[#e3e2e2]  flex justify-center items-center h-[100%] text-center w-[10%] bg-[#d0d0d0]'>24</p>
    </div>
    <div className=' h-[50px] text-center flex justify-between items-center'>
        <p className='border-[1px] border-[#e3e2e2] flex justify-center items-center h-[100%] text-center w-[20%]'>Denim Jean's Pants</p>
        <p className='border-[1px] border-[#e3e2e2]  flex justify-center items-center h-[100%] text-center w-[10%]'>25</p>
        <p className='border-[1px] border-[#e3e2e2]  flex justify-center items-center h-[100%] text-center w-[10%]'>26</p>
        <p className='border-[1px] border-[#e3e2e2]  flex justify-center items-center h-[100%] text-center w-[10%]'>27</p>
        <p className='border-[1px] border-[#e3e2e2]  flex justify-center items-center h-[100%] text-center w-[10%]'>28</p>
        <p className='border-[1px] border-[#e3e2e2]  flex justify-center items-center h-[100%] text-center w-[10%]'>29</p>
        <p className='border-[1px] border-[#e3e2e2]  flex justify-center items-center h-[100%] text-center w-[10%]'>30</p>
        <p className='border-[1px] border-[#e3e2e2]  flex justify-center items-center h-[100%] text-center w-[10%]'>31</p>
        <p className='border-[1px] border-[#e3e2e2]  flex justify-center items-center h-[100%] text-center w-[10%]'>32</p>
        <p className='border-[1px] border-[#e3e2e2]  flex justify-center items-center h-[100%] text-center w-[10%]'>33</p>
    </div>


  </div>
    </div>
    </div>


    <div className=' px-[2%] py-[4%]'>
    <h1 className='text-[40px] font-[FahKwang]  lg:text-[40px] md:text-[25px] md:py-[5%]'>TOPS</h1>
  <div className='border-[1px] text-[16px] h-[auto]'>
    <div className=' h-[50px] text-center flex justify-between items-center'>
        <p className='border-[1px] border-[#e3e2e2] flex justify-center items-center h-[100%] text-center w-[25%] bg-[#082252] text-white'>USA/EU SIZE</p>
        <p className='border-[1px] border-[#e3e2e2]  flex justify-center items-center h-[100%] text-center w-[25%]  bg-[#082252] text-white'>BUST (CM)</p>
        <p className='border-[1px] border-[#e3e2e2]  flex justify-center items-center h-[100%] text-center w-[25%]  bg-[#082252] text-white'>WAIST (CM)</p>
        <p className='border-[1px] border-[#e3e2e2]  flex justify-center items-center h-[100%] text-center w-[25%]  bg-[#082252] text-white'>HIPS (CM)</p>
       
    </div>
    <div className=' h-[50px] text-center flex justify-between items-center'>
        <p className='border-[1px] border-[#e3e2e2] flex justify-center items-center h-[100%] text-center w-[25%] bg-[#d0d0d0]'>XS / 36</p>
        <p className='border-[1px] border-[#e3e2e2]  flex justify-center items-center h-[100%] text-center w-[25%] bg-[#d0d0d0]'>83</p>
        <p className='border-[1px] border-[#e3e2e2]  flex justify-center items-center h-[100%] text-center w-[25%] bg-[#d0d0d0]'>65</p>
        <p className='border-[1px] border-[#e3e2e2]  flex justify-center items-center h-[100%] text-center w-[25%] bg-[#d0d0d0]'>88.5</p>
       
    </div>
    <div className=' h-[50px] text-center flex justify-between items-center'>
        <p className='border-[1px] border-[#e3e2e2] flex justify-center items-center h-[100%] text-center w-[25%]'>S / 38</p>
        <p className='border-[1px] border-[#e3e2e2]  flex justify-center items-center h-[100%] text-center w-[25%]'>88</p>
        <p className='border-[1px] border-[#e3e2e2]  flex justify-center items-center h-[100%] text-center w-[25%]'>70</p>
        <p className='border-[1px] border-[#e3e2e2]  flex justify-center items-center h-[100%] text-center w-[25%]'>93.5</p>
       
    </div>
    <div className=' h-[50px] text-center flex justify-between items-center'>
        <p className='border-[1px] border-[#e3e2e2] flex justify-center items-center h-[100%] text-center w-[25%] bg-[#d0d0d0]'>M / 40</p>
        <p className='border-[1px] border-[#e3e2e2]  flex justify-center items-center h-[100%] text-center w-[25%] bg-[#d0d0d0]'>93</p>
        <p className='border-[1px] border-[#e3e2e2]  flex justify-center items-center h-[100%] text-center w-[25%] bg-[#d0d0d0]'>75</p>
        <p className='border-[1px] border-[#e3e2e2]  flex justify-center items-center h-[100%] text-center w-[25%] bg-[#d0d0d0]'>98.5</p>
       
    </div>
    <div className=' h-[50px] text-center flex justify-between items-center'>
        <p className='border-[1px] border-[#e3e2e2] flex justify-center items-center h-[100%] text-center w-[25%]'>L /42</p>
        <p className='border-[1px] border-[#e3e2e2]  flex justify-center items-center h-[100%] text-center w-[25%]'>98</p>
        <p className='border-[1px] border-[#e3e2e2]  flex justify-center items-center h-[100%] text-center w-[25%]'>80</p>
        <p className='border-[1px] border-[#e3e2e2]  flex justify-center items-center h-[100%] text-center w-[25%]'>103.5</p>
       
    </div>
    <div className=' h-[50px] text-center flex justify-between items-center'>
        <p className='border-[1px] border-[#e3e2e2] flex justify-center items-center h-[100%] text-center w-[25%] bg-[#d0d0d0]'>XL / 44</p>
        <p className='border-[1px] border-[#e3e2e2]  flex justify-center items-center h-[100%] text-center w-[25%] bg-[#d0d0d0]'>103</p>
        <p className='border-[1px] border-[#e3e2e2]  flex justify-center items-center h-[100%] text-center w-[25%] bg-[#d0d0d0]'>85</p>
        <p className='border-[1px] border-[#e3e2e2]  flex justify-center items-center h-[100%] text-center w-[25%] bg-[#d0d0d0]'>108.5</p>
       
    </div>
    <div className=' h-[50px] text-center flex justify-between items-center'>
        <p className='border-[1px] border-[#e3e2e2] flex justify-center items-center h-[100%] text-center w-[25%]'>XXL / 46</p>
        <p className='border-[1px] border-[#e3e2e2]  flex justify-center items-center h-[100%] text-center w-[25%]'>110.5</p>
        <p className='border-[1px] border-[#e3e2e2]  flex justify-center items-center h-[100%] text-center w-[25%]'>92.5</p>
        <p className='border-[1px] border-[#e3e2e2]  flex justify-center items-center h-[100%] text-center w-[25%]'>116</p>
       
    </div>

  

  </div>
    </div>


    <div className=' px-[2%] pb-[4%]'>
    <h1 className='text-[40px] font-[FahKwang]  lg:text-[40px] md:text-[25px] md:py-[5%]'>BOTTOMS</h1>
  <div className='border-[1px] text-[16px] h-[auto]'>
    <div className=' h-[50px] text-center flex justify-between items-center'>
        <p className='border-[1px] border-[#e3e2e2] flex justify-center items-center h-[100%] text-center w-[33.33%]  bg-[#082252] text-white'>USA/EU SIZE</p>
        <p className='border-[1px] border-[#e3e2e2]  flex justify-center items-center h-[100%] text-center w-[33.33%]  bg-[#082252] text-white'>WAIST (CM)</p>
        <p className='border-[1px] border-[#e3e2e2]  flex justify-center items-center h-[100%] text-center w-[33.33%]  bg-[#082252] text-white'>HIPS (CM)</p>
       
    </div>
    <div className=' h-[50px] text-center flex justify-between items-center'>
        <p className='border-[1px] border-[#e3e2e2] flex justify-center items-center h-[100%] text-center w-[33.33%] bg-[#d0d0d0]' >XS / 36</p>
        <p className='border-[1px] border-[#e3e2e2]  flex justify-center items-center h-[100%] text-center w-[33.33%] bg-[#d0d0d0] '>65</p>
        <p className='border-[1px] border-[#e3e2e2]  flex justify-center items-center h-[100%] text-center w-[33.33%] bg-[#d0d0d0] '>89</p>
       
    </div>
    <div className=' h-[50px] text-center flex justify-between items-center'>
        <p className='border-[1px] border-[#e3e2e2] flex justify-center items-center h-[100%] text-center w-[33.33%]'>S / 38</p>
        <p className='border-[1px] border-[#e3e2e2]  flex justify-center items-center h-[100%] text-center w-[33.33%]'>70</p>
        <p className='border-[1px] border-[#e3e2e2]  flex justify-center items-center h-[100%] text-center w-[33.33%]'>94</p>
       
    </div>
    <div className=' h-[50px] text-center flex justify-between items-center'>
        <p className='border-[1px] border-[#e3e2e2] flex justify-center items-center h-[100%] text-center w-[33.33%] bg-[#d0d0d0]'>M / 40</p>
        <p className='border-[1px] border-[#e3e2e2]  flex justify-center items-center h-[100%] text-center w-[33.33%] bg-[#d0d0d0]'>75</p>
        <p className='border-[1px] border-[#e3e2e2]  flex justify-center items-center h-[100%] text-center w-[33.33%] bg-[#d0d0d0]'>99</p>
       
    </div>
    <div className=' h-[50px] text-center flex justify-between items-center'>
        <p className='border-[1px] border-[#e3e2e2] flex justify-center items-center h-[100%] text-center w-[33.33%]'>L /42</p>
        <p className='border-[1px] border-[#e3e2e2]  flex justify-center items-center h-[100%] text-center w-[33.33%]'>80</p>
        <p className='border-[1px] border-[#e3e2e2]  flex justify-center items-center h-[100%] text-center w-[33.33%]'>104</p>
       
    </div>
    <div className=' h-[50px] text-center flex justify-between items-center'>
        <p className='border-[1px] border-[#e3e2e2] flex justify-center items-center h-[100%] text-center w-[33.33%] bg-[#d0d0d0]'>XL / 44</p>
        <p className='border-[1px] border-[#e3e2e2]  flex justify-center items-center h-[100%] text-center w-[33.33%] bg-[#d0d0d0]'>85</p>
        <p className='border-[1px] border-[#e3e2e2]  flex justify-center items-center h-[100%] text-center w-[33.33%] bg-[#d0d0d0]'>109</p>
       
    </div>
    <div className=' h-[50px] text-center flex justify-between items-center'>
        <p className='border-[1px] border-[#e3e2e2] flex justify-center items-center h-[100%] text-center w-[33.33%]'>XXL / 46</p>
        <p className='border-[1px] border-[#e3e2e2]  flex justify-center items-center h-[100%] text-center w-[33.33%]'>92.5</p>
        <p className='border-[1px] border-[#e3e2e2]  flex justify-center items-center h-[100%] text-center w-[33.33%]'>116.5</p>
       
    </div>

  

  </div>
    </div>
   </div>
  )
}

export default SizeChart