import { Link } from "react-router-dom";
import coll1 from "../../images/Home/homepage-2nd-row-left.jpg"
import coll2 from "../../images/Home/homepage-2nd-row-right.jpg"
import row1 from "../../images/Home/home-page-1st-row.jpg"
import row2Left from "../../images/Home/homepage-2nd-row-left.jpg"
import row2Right from "../../images/Home/homepage-2nd-row-right.jpg"
import row3 from "../../images/Home/home-page-3rd-banner.jpg"
 
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
function CollectionImages() {
   const [t, i18n] = useTranslation("global");

  useEffect(() => {
    const selectedLang = localStorage.getItem("lang");
    if (selectedLang) {
      i18n.changeLanguage(selectedLang);
    }
  }, [i18n]);

  return (
    <div className="flex flex-col justify-center items-center" >

<Link to="/collection/prelude" className="relative  h-[auto] py-[5%]">
       
       <div  className="absolute  top-[30%] lg:top-[20%] flex flex-col w-[100%] pl-[9%]  justify-center items-center">
           {/* <h1 className="text-[2.5vw] lg:text-[3.5vw] font-[400] font-[FahKwang] text-center leading-[0.9] text-black">{t("homePage.banners.second.title")}</h1>
           <h1 className="text-[2.5vw] lg:text-[3.5vw] font-[400] font-[FahKwang] text-center leading-[0.9] text-black "> {t("homePage.banners.second.desc")}</h1>
           <h2 className="text-[1.8vw] lg:text-[2.8vw] font-[400] font-[Playball-Regular]">{t("homePage.banners.second.title2")}</h2>
           <p className="w-[30%] lg:w-[40%] text-[1vw] lg:text-[1.5vw] text-center text-black pb-[1%] font-[FahKwang] tracking-[2px]">{t("homePage.banners.second.coll")}</p> */}
           {/* <Link  className="hover:text-[black] flex justify-center items-center text-[0.8vw] lg:text-[1.5vw]  hover:border-white  font-[600] border-black border-[1px] text-black px-[15px] py-[5px] lg:px-[2px] lg:py-[1px] rounded-[20px]">
             {t("homePage.banners.viewCollection")}
           </Link> */}
       </div>
   
   <img src={row1} alt="Image" className="w-full"/>
</Link>
<div className=" flex justify-evenly xl:justify-between items-center ">
  <div className="w-[30%] xl:w-[47%]">
  <img src={row2Left} className="w-[100%] h-[100%] objec-contain"/>
  </div>

  <div className="w-[30%] xl:w-[47%]">
  <img src={row2Right} className="w-[100%] h-[100%] objec-contain"/>
  </div>

</div>

<Link to="/collection/prelude" className="relative  h-[auto] pt-[5%]">
       
       <div  className="absolute  top-[30%] lg:top-[20%] flex flex-col w-[100%] pl-[9%]  justify-center items-center">
           {/* <h1 className="text-[2.5vw] lg:text-[3.5vw] font-[400] font-[FahKwang] text-center leading-[0.9] text-black">{t("homePage.banners.second.title")}</h1>
           <h1 className="text-[2.5vw] lg:text-[3.5vw] font-[400] font-[FahKwang] text-center leading-[0.9] text-black "> {t("homePage.banners.second.desc")}</h1>
           <h2 className="text-[1.8vw] lg:text-[2.8vw] font-[400] font-[Playball-Regular]">{t("homePage.banners.second.title2")}</h2>
           <p className="w-[30%] lg:w-[40%] text-[1vw] lg:text-[1.5vw] text-center text-black pb-[1%] font-[FahKwang] tracking-[2px]">{t("homePage.banners.second.coll")}</p> */}
           {/* <Link  className="hover:text-[black] flex justify-center items-center text-[0.8vw] lg:text-[1.5vw]  hover:border-white  font-[600] border-black border-[1px] text-black px-[15px] py-[5px] lg:px-[2px] lg:py-[1px] rounded-[20px]">
             {t("homePage.banners.viewCollection")}
           </Link> */}
       </div>
   
   <img src={row3} alt="Image" className="w-full"/>
</Link>


    </div>
  
  )
}

export default CollectionImages
